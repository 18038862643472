import { EMAIL_REGEX } from 'app/js/utils/validation/validation'

const VALIDATION_RULES = {
  email: {
    required: 'required',
    pattern: {
      value: EMAIL_REGEX,
      message: 'valid'
    }
  }
}

const getValidationRules = (key) => VALIDATION_RULES[key] ? VALIDATION_RULES[key] : {}

export default getValidationRules
