// Scrolls to the top of the page when navigating from one page to the next.
// Useful for mobile browsers.
// https://reactrouter.com/web/guides/scroll-restoration
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop
