import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

const PasswordRequirementsInfo = ({ parentName }) => {
  const { t } = useTranslation()
  const getLabel = (requirement) => t(`${parentName}.password.validation.${requirement}`)
  return (
    <div id="password-requirements">
      <p className="pb-3">
        <strong>
          <Trans i18nKey={`${parentName}.password.header.info`} components={[<span className="no-wrap">dummyChild</span>]} />
        </strong>
      </p>
      <ul>
        <li>{getLabel('lowercase')}</li>
        <li>{getLabel('uppercase')}</li>
        <li>{getLabel('length')}</li>
        <li>{getLabel('digit')}</li>
        <li>{getLabel('email')}</li>
        <li>{getLabel('firstName')}</li>
        <li>{getLabel('lastName')}</li>
      </ul>
    </div>
  )
}

export default PasswordRequirementsInfo
