import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import Confirmation from 'app/js/components/common/Confirmation'
import PageTitle from 'app/js/components/common/PageTitle'

const Expired = ({ type }) => {
  const PAGE_NAME = 'Expired'

  // Navigation
  const navigate = useNavigate()

  // Translation
  const { t } = useTranslation()

  // Button click handler
  const register = () => navigate('/')

  const button = (
    <button className="button is-primary is-fullwidth" onClick={register}>
      {t(`${PAGE_NAME}.buttons.gotoregister.label`)}
    </button>
  )
  const title = t(`${PAGE_NAME}.${type}.title`)
  const message = t(`${PAGE_NAME}.${type}.label`)

  return (
    <div>
      <PageTitle pageName={`${PAGE_NAME}.${type}`} />
      <Header />
      <main>
        <Confirmation title={title} message={message} button={button} />
      </main>
      <Footer isEnrolment={true} className="is-enrolment" />
    </div>
  )
}

export default Expired
