import React from 'react'
import Modal from './Modal'
import FocusTrap from 'focus-trap-react'

const ModalFocusTrap = ({unmountTrap, className, children}) => {

  const nameOfClass = className
  const handleEscape = (e) => {
    if(e.code === 'Escape' || e.keyCode === 27 || e.key === 'Escape'){
      unmountTrap()
    }
  }

  return (
    <FocusTrap>
      <div className="trap is-active" role="dialog" aria-modal="true" aria-labelledby="dialog-heading" onKeyDown={handleEscape}>
        <Modal className={nameOfClass}>
          {children}
        </Modal>
      </div>
    </FocusTrap>
  )
}
export default ModalFocusTrap