import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import Confirmation from 'app/js/components/common/Confirmation'
import PageTitle from 'app/js/components/common/PageTitle'

const ResendFailed = () => {
  const PAGE_NAME = 'ResendFailed'
  const navigate = useNavigate()
  const { t } = useTranslation()
  const getText = (key) => t(`${PAGE_NAME}.${key}`)
  const register = () => navigate('/')
  const button = (
    <button className="button is-primary is-fullwidth" onClick={register}>
      {getText('buttons.gotoregister.label')}
    </button>
  )
  const icon = (
    <div className="icon">
      {/* Start of SVG icon. Necessary for it's color to be manipulated in CSS*/}
      <svg className="is-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
          <path
            d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
			C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
			c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"
          />
        </g>
        <g>
          <path
            d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877
			C276,137.831,267.046,128.877,256,128.877z"
          />
        </g>
        <g>
          <circle cx="256" cy="349.16" r="27" />
        </g>
      </svg>
      {/* End of SVG icon */}
    </div>
  )

  const title = getText('title')
  const message = getText('message')
  return (
    <div>
      <PageTitle pageName={PAGE_NAME} />
      <Header />
      <main>
        <Confirmation icon={icon} title={title} message={message} button={button} />
      </main>
      <Footer isEnrolment={true} className="is-enrolment" />
    </div>
  )
}

export default ResendFailed
