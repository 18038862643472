import React from 'react'

const Toast = ({ icon, message, onClose, className }) => (
  <div className="toast-container" role="alert">
    <div className={`toast-notification ${className}`}>
      <p>
        {icon && <span className="toast-icon">{icon}</span>}
        {message}
      </p>
      <div className="toast-close">
        <button onClick={onClose} className="link"><i className="fas fa-times fa-sm"></i></button>
      </div>
    </div>
  </div>
)


export default Toast