import { useRef, useEffect, useState } from 'react'
import { useFormState } from 'react-hook-form'

/*
  Sets focus to an arbitraty element when a form is submitted and has validation errors. 
  This hook is useful for setting focus to error messages on unsuccessful form 
  submission so that the screen reader reads the list of errors.

  Why not set the focus in the the handleSubmit's onError function, you ask? 
  Because react-hook-form sets focus to the first invalid element immediately AFTER evaluating
  onError.

  https://react-hook-form.com/api/useform/handlesubmit
  https://github.com/react-hook-form/react-hook-form/blob/v7.41.5/src/logic/createFormControl.ts#L1060
 */
const useFocusOnInvalidFormSubmission = ({ control, manualFocusOverride }) => {
  const ref = useRef(null)
  const [localSubmitCount, setLocalSubmitCount] = useState(0)
  const [focused, setFocused] = useState(false)
  const { isValid, submitCount } = useFormState({ control })

  // Sets focus manually. Useful when an error is added to the list after an API call
  useEffect(() => {
    if (manualFocusOverride && !focused && ref.current) {
      ref.current.focus()
      setFocused(true)
    } else if (!manualFocusOverride) {
      // focus prop was toggled off. Reset the focused state for the next time it is toggled on
      setFocused(false)
    }
  }, [manualFocusOverride, focused, ref])

  // Sets focus when the form is submitted and has errors
  useEffect(() => {
    if (submitCount > localSubmitCount && !isValid && ref.current) {
      ref.current.focus()
    }
    setLocalSubmitCount(submitCount)
  }, [isValid, localSubmitCount, submitCount, ref])

  return ref
}

export default useFocusOnInvalidFormSubmission
