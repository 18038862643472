import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import { useTranslation } from 'react-i18next'

const CountdownTimer = ({ onTimeExpired, expiryDate }) => {
  const componentName = 'CountdownTimer'
  const { t } = useTranslation()

  const getMinutesWithUnits = (minutes) => {
    switch (minutes) {
      case 0:
        return ''
      case 1:
        return t(`${componentName}.minutes.singular`, { minutes })
      default:
        return t(`${componentName}.minutes.plural`, { minutes })
    }
  }

  const getSecondsWithUnits = (seconds) => {
    switch (seconds) {
      case 1:
        return t(`${componentName}.seconds.singular`, { seconds })
      default:
        return t(`${componentName}.seconds.plural`, { seconds })
    }
  }

  const getAccessibleTimerLabel = (minutes, seconds) => t(`${componentName}.accessibleLabel`, { time: `${getMinutesWithUnits(minutes)}${getSecondsWithUnits(seconds)}` })

  const timerRenderer = ({ className, minutes, seconds }) => (
    <div>
      <span className={className} aria-hidden="true">
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
      <span className="sr-only">{getAccessibleTimerLabel(minutes, seconds)}</span>
    </div>
  )

  return (
    <div className="timer-container">
      <div className="success">
        <div className="info-text timer-message">{t(`${componentName}.message`)}</div>
      </div>
      <div className="countdown-timer">
        <Countdown onComplete={onTimeExpired} date={expiryDate} renderer={timerRenderer} />
      </div>
    </div>
  )
}

export default CountdownTimer
