import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const PageTitle = ({ pageName }) => {
  const { t, i18n } = useTranslation()
  const pageTitleText = i18n.exists(`${pageName}.pageTitle`) ? t(`${pageName}.pageTitle`) : t('Common.pageTitle')
  return (
    <Helmet>
      <title>{pageTitleText}</title>
    </Helmet>
  )
}

export default PageTitle