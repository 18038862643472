import React from 'react'
import { useTranslation } from "react-i18next"

const HelpMessage = ({ parentName, name, errors, id }) => {
  const { t, i18n } = useTranslation()
  if (i18n.exists(`${parentName}.${name}.help`) && !errors[name]) {
    return <p className="help" id={id}>{t(`${parentName}.${name}.help`)}</p>
  }
  return null
}

export default HelpMessage