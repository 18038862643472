import React from 'react'
import { Trans } from 'react-i18next'
import { getSignInUrl } from 'app/js/configuration/environmentConfig'


const SignInPage = () => (
  <Trans
  i18nKey="AlreadyHaveAccount.SignIn"
  components={[<a href={getSignInUrl()}>dummyText</a>]}
/>
)

export default SignInPage
