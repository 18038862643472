import React from 'react'
import EnrolmentPageHelp from 'app/js/components/common/PageHelp/EnrolmentPageHelp'
import AutoFocusHeading from './AutoFocusHeading'

const Confirmation = ({ icon, title, message, button }) => (
  <div className="wrapper">
    <div className="container is-confirmation">
      <div className="form-container">
        {icon && <div className="header-icon">{icon}</div>}
        <AutoFocusHeading className="extra-margin">{title}</AutoFocusHeading>
        <div className="message">
          <p>{message}</p>
        </div>
        {button}
        <EnrolmentPageHelp />
      </div>
    </div>
  </div>
)

export default Confirmation
