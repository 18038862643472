import React, { forwardRef } from 'react'
import ErrorLink from 'app/js/components/common/ErrorLink'
import { Trans } from 'react-i18next'

const ErrorList = forwardRef(({ parentName, errors }, ref) => {
  const hasErrors = () => Object.keys(errors).length > 0

  if (hasErrors()) {
    return (
      <div className="help-info no-flex error" id="error-collection" tabIndex="0" ref={ref}>
        <p className="pb-3">
          <strong>
            <Trans i18nKey="Common.errorList.title" components={[<span className="no-wrap">dummyChild</span>]} />
          </strong>
        </p>
        <ul>
          {Object.keys(errors).map((errorKey) => {
            const error = errors[errorKey]
            const key = `${error.ref.id}:${error.type || error.message}`
            return (
              <li key={key}>
                <ErrorLink parentName={parentName} error={error} />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
  return null
})

export default ErrorList
