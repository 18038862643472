import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { getPrivacyPolicyUrl } from 'app/js/utils/localization/i18n-utils'

const Footer = ({ isEnrolment, className }) => {

  const PAGE_NAME = 'Footer'
  const { t } = useTranslation()

  const PRIVACY_POLICY_URL = getPrivacyPolicyUrl(t)
  const PRIVACY_POLICY_I18N_KEY = t('Footer.privacy.label')
  const PRIVACY_POLICY_ARIA_LABEL = t('Footer.ariaLabels.wawanesaPrivacy')
  const PRIVACY_POLICY_LINK = (
    <a aria-label={PRIVACY_POLICY_ARIA_LABEL} href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
      dummyText
    </a>
  )

  const TERMS_URL = '/terms'
  const TERMS_I18N_KEY = t('Footer.terms')
  const TERMS_ARIA_LABEL = t('Footer.ariaLabels.wawanesaTerms')
  const TERMS_LINK = (
    <a aria-label={TERMS_ARIA_LABEL} href={TERMS_URL} target="_blank" rel="noopener noreferrer">
      dummyText
    </a>
  )

  const COPYRIGHT = <div className="copyright">{t(`${PAGE_NAME}.copyright`).replace('CurrentYear', new Date().getFullYear())}</div>

  const LINKS = (
    <div className="legal">
      <Trans i18nKey={PRIVACY_POLICY_I18N_KEY} components={[PRIVACY_POLICY_LINK]} /> | <Trans i18nKey={TERMS_I18N_KEY} components={[TERMS_LINK]} />
    </div>
  )

  return (
    <footer className="wrapper">
      <div className={`footer ${className}`}>
        {isEnrolment ? COPYRIGHT : LINKS}
        {isEnrolment ? LINKS : COPYRIGHT}
      </div>
    </footer>
  )
}

export default Footer
