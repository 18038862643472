import React from 'react'
import { useTranslation } from 'react-i18next'

const ErrorLink = ({ parentName, error }) => {
  const { t, i18n } = useTranslation()
  const name = error.ref.name
  const baseKey = `${parentName}.${name}.validation.${error.message || error.type}`

  const link = `#${name}`
  const label = t([`${baseKey}.label`, baseKey])

  const ariaDescribedbyKey = `${baseKey}.ariaDescribedById`
  let ariaDescribedBy = {}
  if (i18n.exists(ariaDescribedbyKey)) {
    ariaDescribedBy = { 'aria-describedby': t(ariaDescribedbyKey) }
  }

  return (
    <a href={link} {...ariaDescribedBy}>
      {label}
    </a>
  )
}

export default ErrorLink
