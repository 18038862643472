import useAutoFocus from 'app/js/hooks/useAutoFocus'
import React from 'react'

const AutoFocusHeading = ({ className, children }) => {
  const ref = useAutoFocus()
  return (
    <h1 className={className} tabIndex="-1" ref={ref}>
      {children}
    </h1>
  )
}

export default AutoFocusHeading