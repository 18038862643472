import React from 'react'
import Helmet from 'react-helmet'

const Modal = (props) => (
  <div className={props.className}>
    <Helmet>
      <body className="modal-open" />
    </Helmet>
    <div className="modal-overlay"></div>
    <div className="modal is-active">
      {props.children}
    </div>
  </div>
)
export default Modal