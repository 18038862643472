const { publicWebsiteUrl, serviceHost, serviceBaseUri, serviceBaseUriM4, enableReCaptcha, reCaptchaKey, signInUrl, featureFlagKey } = window.runtimeConfiguration

const getPublicWebsiteUrl = () => publicWebsiteUrl
const getServiceHost = () => serviceHost
const getServiceBaseUri = () => serviceBaseUri
const getServiceBaseUriM4 = () => serviceBaseUriM4
const getEnableReCaptcha = () => enableReCaptcha
const getRecaptchaApiKey = () => reCaptchaKey
const getSignInUrl = () => signInUrl
const getSplitConfig = () => ({
  core: {
    authorizationKey: featureFlagKey,
    key: 'CUSTOMER_ID'
  }
})

export { getPublicWebsiteUrl, getServiceHost, getServiceBaseUri, getServiceBaseUriM4, getEnableReCaptcha, getRecaptchaApiKey, getSignInUrl, getSplitConfig }
