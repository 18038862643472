import { useRef, useEffect } from 'react'

const useAutoFocus = () => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [ref])

  return ref
}

export default useAutoFocus