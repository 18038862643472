import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from 'app/resources/translations/en/enrolment.json'
import fr from 'app/resources/translations/fr/enrolment.json'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'en': {
                'enrolmentResources': en
            },
            'fr': {
                'enrolmentResources': fr
            }
        },
        fallbackLng: 'en',
        debug: false,
        ns: ['enrolmentResources'],
        defaultNS: 'enrolmentResources',
        lowerCaseLng: true,
        transSupportBasicHtmlNodes: true,
        react: {
            transSupportBasicHtmlNodes: true
        },
        interpolation: {
            escapeValue: false
        },
    })

export default i18n