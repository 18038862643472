import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { getSignInUrl } from 'app/js/configuration/environmentConfig'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import PageTitle from 'app/js/components/common/PageTitle'
import GenericPageHelp from 'app/js/components/common/PageHelp/GenericPageHelp'
import AutoFocusHeading from 'app/js/components/common/AutoFocusHeading'

const ForgotPasswordSuccess = () => {
  const PAGE_NAME = 'ForgotPasswordSuccess'

  // Navigation
  const navigate = useNavigate()
  const signIn = () => {
    window.location.replace(getSignInUrl())
    return false
  }

  // State
  const [email, setEmail] = useState()
  const { state } = useLocation()
  useEffect(() => {
    if (!state || !state.email) {
      navigate('/')
    } else {
      setEmail(state.email)
    }
  }, [state, navigate])

  // Translation
  const { t } = useTranslation()

  return (
    <div>
      <PageTitle pageName={PAGE_NAME} />
      <Header />
      <main className="wrapper">
        <div className="container is-forgot-password is-forgot-password-success">
          <div className="form-container">
            <AutoFocusHeading>{t(`${PAGE_NAME}.title`)}</AutoFocusHeading>

            <div className="help-info success">
              <div className="icon">
                <i className="far fa-check-circle"></i>
              </div>
              <div className="info-text">{t(`${PAGE_NAME}.successMessage`, { email })}</div>
            </div>

            <div className="submit">
              <button className="button is-primary is-fullwidth" onClick={signIn}>
                {t(`${PAGE_NAME}.buttons.signin.label`)}
              </button>
            </div>

            <GenericPageHelp />
          </div>
        </div>
      </main>

      <Footer className="is-forgot-password" />
    </div>
  )
}

export default ForgotPasswordSuccess
