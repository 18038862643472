import React from "react"
import { useTranslation } from "react-i18next"
import { getLogoUrl, getLandingPageUrl } from "app/js/utils/localization/i18n-utils"

const Header = () => {
  const { t } = useTranslation()
  const logoUrl = getLogoUrl(t)
  const landing = getLandingPageUrl(t)
  return (
    <header>
      <div className="header-image">
        <a className="header-link" href={landing}>
          <img src={logoUrl} alt="" className="logo-img" />
        </a>
      </div>
    </header>
  )
}

export default Header
