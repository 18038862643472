import React from 'react'
import { useTranslation } from 'react-i18next'
import ModalFocusTrap from './ModalFocusTrap'

const Loading = () => {
  const { t } = useTranslation()
  const COMPONENT_NAME = 'Loading'
  return (
    <ModalFocusTrap className="loading">
      <div className="modal-card">
        <section className="modal-card-body modal-no-head">
          <div className="content ww-center-content ">
            <h1 tabIndex="0">{t(`${COMPONENT_NAME}.title`)}</h1>
            <p>{t(`${COMPONENT_NAME}.message`)}</p>
            <div className="spinner">
              <div className="bounce1"></div>
              <div className="bounce2"></div>
              <div className="bounce3"></div>
            </div>
          </div>
        </section>
      </div>
    </ModalFocusTrap>
  )
}

export default Loading
