import React from 'react'
import { useTranslation } from 'react-i18next'
import { getSignInUrl } from 'app/js/configuration/environmentConfig'
import ModalFocusTrap from './ModalFocusTrap'
import CloseIcon from 'app/js/components/common/CloseIcon'

const EmailExists = ({ onClose }) => {
  const { t } = useTranslation()
  const PAGE_NAME = 'EmailExists'
  return (
    <ModalFocusTrap unmountTrap={onClose} className="email-exists">
      <div className="modal-card">
        <section className="modal-card-body modal-no-head">
          <div className="content ww-center-content">
            <div className="inline-title">
              <button onClick={onClose} aria-label={t('Common.closeButton')} className="close-button">
                  <CloseIcon />
              </button>
              <h1>{t(`${PAGE_NAME}.title`)}</h1>
            </div>
            <p>{t(`${PAGE_NAME}.message`)}</p>
            <div className="sign-in">
              <a href={getSignInUrl()} className="is-wide button is-primary">
                {t(`${PAGE_NAME}.submit`)}
              </a>
            </div>
          </div>
        </section>
      </div>
    </ModalFocusTrap>
  )
}

export default EmailExists
