import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { getPrivacyPolicyUrl } from 'app/js/utils/localization/i18n-utils'

const TermsContent = ({ parentName, isModal }) => {
  const { t } = useTranslation()
  const emailAddress = t('Common.communicationsEmail')
  const emailLabel = t('Common.communicationsEmailLabel')
  const EMAIL_TAG = <a aria-label={emailLabel} href={`mailto:${emailAddress}`} target="_blank" rel="noopener noreferrer">dummyText</a>
  const PRIVACY_POLICY_URL = getPrivacyPolicyUrl(t)
  const PRIVACY_POLICY_LINK = <a href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">{PRIVACY_POLICY_URL}</a>
  const PHONE_NUMBER_TAG = <span className="phone-number">dummyText</span>
  return (
    <div>
      <p>{t(`${parentName}.paragraphs.0`)}</p>
      <p>{t(`${parentName}.paragraphs.1`)}</p>
      <ul>
        <li>{t(`${parentName}.paragraphs.2.0`)}</li>
        <li>{t(`${parentName}.paragraphs.2.1`)}</li>
        <li>{t(`${parentName}.paragraphs.2.2`)}</li>
        <li>
          <Trans
            i18nKey={`${parentName}.paragraphs.2.3`}
            components={[PHONE_NUMBER_TAG, EMAIL_TAG]}
          />
        </li>
      </ul>
      <p>
        <Trans
          i18nKey={`${parentName}.paragraphs.3`}
        />

      </p>
      {isModal &&
        <p>
          <Trans
            i18nKey={`${parentName}.paragraphs.4`}
          />
        </p>
      }
      <p>
        <Trans
          i18nKey={`${parentName}.paragraphs.5`}
        />

      </p>
      <p>
        <Trans
          i18nKey={`${parentName}.paragraphs.6`}
          components={[PHONE_NUMBER_TAG, EMAIL_TAG]}
        />
      </p>
      <p>
        <Trans
          i18nKey={`${parentName}.paragraphs.7`}
        />
      </p>
      <p>
        {t(`${parentName}.paragraphs.8`)}
        {PRIVACY_POLICY_LINK}
        .
      </p>
    </div>
  )
}

export default TermsContent