import { React, useContext } from 'react'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import Email from 'app/js/pages/Email/Email'
import PasswordCreated from 'app/js/pages/PasswordCreated/PasswordCreated'
import Register from 'app/js/pages/Register/Register'
import Expired from 'app/js/pages/Expired/Expired'
import SetPassword from 'app/js/pages/SetPassword/SetPassword'
import Terms from 'app/js/pages/Terms/Terms'
import ForgotPassword from 'app/js/pages/ForgotPassword/ForgotPassword'
import ForgotPasswordSuccess from 'app/js/pages/ForgotPassword/ForgotPasswordSuccess'
import Maintenance from 'app/js/pages/Maintenance/Maintenance'
import ResendFailed from 'app/js/pages/ResendFailed/ResendFailed'
import ScrollToTop from 'app/js/components/common/ScrollToTop'
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'

const Navigation = () => {
  const featureName = 'operational-maintenance' // "Treatment" (feature flag) name in Split.io
  const { isReady } = useContext(SplitContext) // isReady is provided by Split.IO's SplitContext. It allows the page to know when flag (treatment) status has been retrieved and is ready to be used.
  const treatments = useTreatments([featureName])
  const maintenance = treatments[featureName].treatment === 'on'
  const getProtectedPage = (page) => maintenance ? <Maintenance /> : page
  return isReady && // Effectively blocks until Split.IO is ready before doing any routing.
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={getProtectedPage(<Register />)} />
        <Route path="register/email" element={getProtectedPage(<Email />)} />
        <Route path="register/success" element={getProtectedPage(<PasswordCreated />)} />
        <Route path="register/timeexpired" element={getProtectedPage(<Expired type="time" />)} />
        <Route path="register/linkexpired" element={getProtectedPage(<Expired type="link" />)} />
        <Route path="register/resendfailed" element={getProtectedPage(<ResendFailed />)} />
        <Route path="register/token/:token" element={getProtectedPage(<SetPassword />)} />
        <Route path="terms" element={getProtectedPage(<Terms />)} />
        <Route path="forgot-password" element={getProtectedPage(<ForgotPassword />)} />
        <Route path="recovery-emailed" element={getProtectedPage(<ForgotPasswordSuccess />)} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
}

export default Navigation
