import { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { getEnableReCaptcha } from 'app/js/configuration/environmentConfig'

const useGoogleReCaptchaIfEnabled = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const getRecaptchaToken = useCallback(async (action) => {
    if (!getEnableReCaptcha()) {
      return Promise.resolve()
    }
    if (!executeRecaptcha) {
      return Promise.reject('reCaptcha not initialized')
    }
    return executeRecaptcha(action)
  }, [executeRecaptcha])

  return getRecaptchaToken
}

export default useGoogleReCaptchaIfEnabled