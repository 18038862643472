import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTreatments } from '@splitsoftware/splitio-react'
import EnrolmentService from 'app/js/services/EnrolmentService'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import ErrorMessage from 'app/js/components/common/ErrorMessage'
import PageError from 'app/js/components/common/PageError'
import Loading from 'app/js/components/common/Loading'
import PageTitle from 'app/js/components/common/PageTitle'
import Maintenance from 'app/js/pages/Maintenance/Maintenance'
import PasswordRequirementsInfo from './PasswordRequirementsInfo'
import useGoogleReCaptchaIfEnabled from 'app/js/hooks/useGoogleReCaptchaIfEnabled'
import RecaptchaPageHelp from 'app/js/components/common/PageHelp/RecaptchaPageHelp'
import ErrorList from 'app/js/components/common/ErrorList'
import useFocusOnInvalidFormSubmission from 'app/js/hooks/useFocusOnInvalidFormSubmission'
import CountdownTimer from './CountdownTimer'

const PASSWORD_COMPLEXITY_ERROR_CODE = 'E0000080'

const SetPassword = () => {
  const PAGE_NAME = 'SetPassword'

  // Form
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    register,
    control,
  } = useForm()

  // State
  const { token } = useParams()
  const [stateToken, setStateToken] = useState()
  const [expiryDate, setExpiryDate] = useState()
  const [pageError, setPageError] = useState()
  const [loading, setLoading] = useState(true)
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [showMaintenance, setShowMaintenance] = useState(false)
  const [focusErrorList, setFocusErrorList] = useState(false)

  // Navigation
  const navigate = useNavigate()

  // Translation
  const { t } = useTranslation()

  // ReCaptcha
  const getRecaptchaToken = useGoogleReCaptchaIfEnabled()

  // Use Mule 4 endpoints feature flag
  const featureName = 'operational-use-m4-endpoints'
  const treatments = useTreatments([featureName])
  const useM4Endpoints = treatments[featureName].treatment === 'on'

  // Submit handler - set the password
  const next = async (data) => {
    setLoading(true)
    setPageError()
    setFocusErrorList(false)
    const recaptchaToken = await getRecaptchaToken()
    EnrolmentService.activateAccount({ stateToken, ...data }, recaptchaToken, useM4Endpoints)
      .then(() => {
        navigate('/register/success')
      })
      .catch((error) => {
        setLoading(false)
        const error_code = error?.response?.data?.code
        if (error_code === PASSWORD_COMPLEXITY_ERROR_CODE) {
          setError('password', { type: 'complexity' })
          setFocusErrorList(true)
        } else {
          setPageError('generic')
        }
      })
  }

  // Token activation - called when the page is loaded
  const activateToken = () => {
    ;(async () => {
      // Make sure token and navigate have been initialized to avoid multiple API calls
      if (token && navigate) {
        try {
          const recaptchaToken = await getRecaptchaToken()
          EnrolmentService.activateToken(token, recaptchaToken, useM4Endpoints)
            .then((response) => {
              setStateToken(response.data.state_token)
              setExpiryDate(response.data.expiry_date)
              setLoading(false)
              setShowMaintenance(false)
              setPageError()
            })
            .catch((error) => {
              if (error?.response?.status === 401) {
                navigate('/register/linkexpired')
              }
              // Currently Mule returns 500 Server Error if the token is invalid.
              // Axios cannot differentiate between this and any other network error (Eg, Mule is down).
              // Until Mule returns something different for Expired Token we cannot branch on the different scenarios.
              // For now the below code will never execute, but it is being left here
              // to make it easy to implement in the future.
              else {
                setShowMaintenance(true)
              }
              setLoading(false)
            })
        } catch (error) {
          // reCaptcha error can sometimes occur due to it not being initialized yet. Swallow the error since the function will be re-run once it is initialized
        }
      }
    })()
  }
  useEffect(activateToken, [getRecaptchaToken, token, navigate, useM4Endpoints])

  // Time expired handler - redirect to error page and call API so we can track these events
  const timeExpired = () => {
    if (expiryDate) {
      navigate('/register/timeexpired')
      EnrolmentService.authnExpiry(useM4Endpoints)
    }
  }

  // Validation
  const errorListRef = useFocusOnInvalidFormSubmission({ control, manualFocusOverride: focusErrorList })
  const hasError = (inputName) => typeof errors[inputName] !== 'undefined'
  const getErrorStyles = (inputName) => (hasError(inputName) ? 'error' : '')
  const getEyeStyles = () => (passwordVisible ? 'fa-eye-slash' : 'fa-eye')

  // Password utils functions
  const togglePasswordVisible = (event) => {
    event.preventDefault()
    setPasswordVisible(!passwordVisible)
  }
  const validatePasswordsMatch = (verifyPassword) => {
    const password = getValues().password
    const passwordsMatch = password === verifyPassword
    return passwordsMatch || 'match'
  }

  if (showMaintenance) {
    return <Maintenance />
  } else {
    return (
      <div>
        <PageTitle pageName={PAGE_NAME} />
        <Header />
        <main className="wrapper">
          <div className="container is-enrolment">
            <div className="form-container">
              {stateToken && <CountdownTimer onTimeExpired={timeExpired} expiryDate={expiryDate} />}
              <h1 className="extra-margin">{t(`${PAGE_NAME}.title`)}</h1>

              {pageError && <PageError parentName={PAGE_NAME} error={pageError} />}
              <PasswordRequirementsInfo parentName={PAGE_NAME} />
              <ErrorList parentName={PAGE_NAME} errors={errors} ref={errorListRef} />

              {loading && <Loading />}
              {stateToken && (
                <form onSubmit={handleSubmit(next)} autoComplete="off" noValidate>
                  {/* Password */}
                  <div className="field">
                    <label className="label" htmlFor="password">
                      {t(`${PAGE_NAME}.password.label`)}
                    </label>
                    <div className="control has-icons-right">
                      <input
                        id="password"
                        {...register('password', { required: 'required' })}
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder={t(`${PAGE_NAME}.password.placeholder`)}
                        className={`input ${getErrorStyles('password')}`}
                        aria-required="true"
                        aria-describedby="firstpass password-requirements"
                        aria-invalid={hasError('password')}
                      />
                      <span className="icon is-small is-right" onClick={togglePasswordVisible}>
                        <i className={`far ${getEyeStyles()}`}></i>
                      </span>
                    </div>
                    <ErrorMessage parentName={PAGE_NAME} name="password" errors={errors} id="firstpass" />
                  </div>

                  {/* Verify password */}
                  <div className="field">
                    <label className="label" htmlFor="verifyPassword">
                      {t(`${PAGE_NAME}.verifyPassword.label`)}
                    </label>
                    <div className="control has-icons-right">
                      <input
                        id="verifyPassword"
                        {...register('verifyPassword', { validate: validatePasswordsMatch })}
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder={t(`${PAGE_NAME}.verifyPassword.placeholder`)}
                        className={`input ${getErrorStyles('verifyPassword')}`}
                        aria-required="true"
                        aria-describedby="secondpass password-requirements"
                        aria-invalid={hasError('verifyPassword')}
                      />
                      <span className="icon is-small is-right" onClick={togglePasswordVisible}>
                        <i className={`far ${getEyeStyles()}`}></i>
                      </span>
                    </div>
                    <ErrorMessage parentName={PAGE_NAME} name="verifyPassword" errors={errors} id="secondpass" />
                  </div>

                  <div className="submit last-form-element">
                    <button type="submit" disabled={loading} className="button is-primary is-fullwidth">
                      {t(`${PAGE_NAME}.submit.label`)}
                    </button>
                  </div>
                </form>
              )}

              <RecaptchaPageHelp />
            </div>
          </div>
        </main>
        <Footer isEnrolment={true} className="is-enrolment" />
      </div>
    )
  }
}
export default SetPassword
