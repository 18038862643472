import { EMAIL_REGEX, POSTAL_CODE_REGEX } from 'app/js/utils/validation/validation'

const VALIDATION_RULES = {
  email: {
    required: 'required',
    pattern: {
      value: EMAIL_REGEX,
      message: 'valid'
    }
  },
  claimNumber: {
    required: 'required',
    validate: value => value.length === 15 || 'length'
  },
  postalCode: {
    required: 'required',
    pattern: {
      value: POSTAL_CODE_REGEX,
      message: 'valid'
    }
  },
  terms: {
    validate: terms => terms ? true : 'required'
  }
}

const getValidationRules = (key) => VALIDATION_RULES[key] ? VALIDATION_RULES[key] : {}

export default getValidationRules
