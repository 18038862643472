import React from 'react'
import { useTranslation } from 'react-i18next'
import TermsContent from '../Terms/TermsContent'
import Header from 'app/js/components/common/Header'
import PageTitle from 'app/js/components/common/PageTitle'

const Terms = () => {
  const PAGE_NAME = 'Terms'
  const { t } = useTranslation()
  return (
    <div className="terms">
      <PageTitle pageName={PAGE_NAME} />
      <Header />
      <main className="wrapper">
        <div className="container is-enrolment">
          <div className="modal-body">
            <h1>{t(`${PAGE_NAME}.title`)}</h1>
            <TermsContent parentName={PAGE_NAME} isModal={false} />
          </div>
        </div>
      </main>
    </div>
  )
}

export default Terms