import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTreatments } from '@splitsoftware/splitio-react'
import EnrolmentService from 'app/js/services/EnrolmentService'
import getValidationRules from './ValidationRules'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import PageTitle from 'app/js/components/common/PageTitle'
import ErrorMessage from 'app/js/components/common/ErrorMessage'
import HelpMessage from 'app/js/components/common/HelpMessage'
import PageError from 'app/js/components/common/PageError'
import GenericPageHelp from 'app/js/components/common/PageHelp/GenericPageHelp'
import RecaptchaPageHelp from 'app/js/components/common/PageHelp/RecaptchaPageHelp'
import Loading from 'app/js/components/common/Loading'
import useGoogleReCaptchaIfEnabled from 'app/js/hooks/useGoogleReCaptchaIfEnabled'
import ErrorList from 'app/js/components/common/ErrorList'
import useFocusOnInvalidFormSubmission from 'app/js/hooks/useFocusOnInvalidFormSubmission'

const ForgotPassword = () => {
  const PAGE_NAME = 'ForgotPassword'

  // State
  const [loading, setLoading] = useState(false)
  const [pageError, setPageError] = useState(false)

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm()

  // Navigation
  const navigate = useNavigate()

  // Translation
  const { t } = useTranslation()

  // reCaptcha
  const getRecaptchaToken = useGoogleReCaptchaIfEnabled()

  // Use Mule 4 endpoints feature flag
  const featureName = 'operational-use-m4-endpoints'
  const treatments = useTreatments([featureName])
  const useM4Endpoints = treatments[featureName].treatment === 'on'

  // Submit handler - reset password
  const next = async (data) => {
    setPageError()
    setLoading(true)
    const recaptchaToken = await getRecaptchaToken()
    EnrolmentService.resetPassword(data, recaptchaToken, useM4Endpoints)
      .then(() => {
        navigate('/recovery-emailed', { state: { email: data.email } })
      })
      .catch(() => {
        setLoading(false)
        setPageError('generic')
      })
  }

  // Validation
  const errorListRef = useFocusOnInvalidFormSubmission({ control })
  const hasValidationError = (inputName) => typeof errors[inputName] !== 'undefined'
  const hasError = (inputName) => hasValidationError(inputName)
  const getErrorStyles = (inputName) => hasError(inputName) ? 'error' : ''

  return (
    <div>
      <PageTitle pageName={PAGE_NAME} />
      <Header />
      <main className="wrapper">
        <div className="container is-forgot-password">
          <div className="form-container">
            <h1 className="extra-margin">{t(`${PAGE_NAME}.title`)}</h1>

            {loading && <Loading />}

            {pageError && <PageError parentName={PAGE_NAME} error={pageError} />}
            <ErrorList parentName={PAGE_NAME} errors={errors} ref={errorListRef} />

            <form onSubmit={handleSubmit(next)} autoComplete="off" noValidate>
              {/* email address */}
              <div className="field">
                <label htmlFor="email" className="label">
                  {t(`${PAGE_NAME}.email.label`)}
                </label>
                <div className="control">
                  <input
                    id="email"
                    {...register('email', getValidationRules('email'))}
                    type="email"
                    placeholder={t(`${PAGE_NAME}.email.placeholder`)}
                    className={`input ${getErrorStyles('email')}`}
                    aria-required="true"
                    aria-invalid={hasError('email')}
                  />
                </div>
                <HelpMessage parentName={PAGE_NAME} name="email" errors={errors} />
                <ErrorMessage parentName={PAGE_NAME} name="email" errors={errors} />
              </div>

              <div className="submit">
                <button type="submit" disabled={loading} className="button is-primary is-fullwidth">
                  {t(`${PAGE_NAME}.submit.label`)}
                </button>
              </div>
            </form>

            <GenericPageHelp />
            <RecaptchaPageHelp />
          </div>
        </div>

      </main>

      <Footer className="is-forgot-password" />
    </div>
  )
}

export default ForgotPassword
