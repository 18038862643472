import React from 'react'
import { useTranslation } from 'react-i18next'
import { getSignInUrl } from 'app/js/configuration/environmentConfig'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import PageTitle from 'app/js/components/common/PageTitle'
import AutoFocusHeading from 'app/js/components/common/AutoFocusHeading'

const PasswordCreated = () => {
  const PAGE_NAME = 'PasswordCreated'
  const { t } = useTranslation()
  const signIn = () => (window.location.href = getSignInUrl())
  return (
    <div>
      <PageTitle pageName={PAGE_NAME} />
      <Header />
      <main className="wrapper">
        <div className="container is-success">
          <div>
            <div className="success-check">
              <div className="icon">
                <i className="far fa-check-circle"></i>
              </div>
            </div>
            <div>
              <AutoFocusHeading className="is-success">{t(`${PAGE_NAME}.title`)}</AutoFocusHeading>
              <div className="confirmation-text is-success">{t(`${PAGE_NAME}.password_created`)}</div>
            </div>
            <div className="wide-container">
              <button className="button is-primary is-fullwidth" onClick={signIn}>
                {t(`${PAGE_NAME}.buttons.signin.label`)}
              </button>
            </div>
          </div>
        </div>
      </main>

      <Footer isEnrolment={true} className="is-enrolment" />
    </div>
  )
}

export default PasswordCreated
