import useAutoFocus from 'app/js/hooks/useAutoFocus'
import React from 'react'
import { useTranslation } from 'react-i18next'

const PageError = ({ parentName, error }) => {
  const ref = useAutoFocus()
  const { i18n, t } = useTranslation()
  const messageKey = `${parentName}.pageValidation.${error}`
  let message
  if (error && i18n.exists(messageKey)) {
    message = t(messageKey)
  }
  return (
    <div className="help-info-container" tabIndex="0" ref={ref}>
      {message && (
        <div className="help-info error page-error">
          <div className="icon">
            {/* Start of SVG icon. Necessary for it's color to be manipulated in CSS*/}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                className="svg-exclaim"
                d="M256 0C114.5 0 0 114.5 0 256s114.5 256 256 256 256-114.5 256-256S397.5 0 256 0zm0 472c-119.4 0-216-96.6-216-216S136.6 40 256 40s216 96.6 216 216-96.6 216-216 216zm0-343.1c-11 0-20 9-20 20v128.8c0 11 9 20 20 20s20-9 20-20V148.9c0-11.1-9-20-20-20zm0 193.3c14.9 0 27 12.1 27 27s-12.1 27-27 27-27-12.1-27-27 12.1-27 27-27z"
              />
            </svg>

            {/* End of SVG icon */}
          </div>
          <p>{message}</p>
        </div>
      )}
    </div>
  )
}

export default PageError
