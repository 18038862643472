import React from 'react'
import PageHelp from 'app/js/components/common/PageHelp/PageHelp'

const GenericPageHelp = () => (
  <PageHelp
    msgKey={`Common.pageHelp.generic`}
    components={[<span className="no-wrap">dummyChild</span>]}
  />
)

export default GenericPageHelp