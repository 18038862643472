import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Header from 'app/js/components/common/Header'
import Footer from 'app/js/components/common/Footer'
import PageTitle from 'app/js/components/common/PageTitle'

const Maintenance = () => {
  const PAGE_NAME = 'Maintenance'
  const { t } = useTranslation()
  const PHONE_NUMBER_TAG = <span className="phone-number"></span>
  return (
    <div>
      <PageTitle pageName={PAGE_NAME} />
      <Header />
      <main className="wrapper">
        <div className="container is-maintenance">
          <div className="form-container">
            <h1 className="extra-margin">{t(`${PAGE_NAME}.title`)}</h1>
            <div className="message">
              <p>{t(`${PAGE_NAME}.message.0`)}</p>
              <p>
                <Trans i18nKey={`${PAGE_NAME}.message.1`} components={[PHONE_NUMBER_TAG]} />
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer isEnrolment={true} className="is-maintenance" />
    </div>
  )
}

export default Maintenance
