import React from 'react'
import { useTranslation } from 'react-i18next'
import ModalFocusTrap from 'app/js/components/common/ModalFocusTrap'
import TermsContent from './TermsContent'
import CloseIcon from 'app/js/components/common/CloseIcon'

const TermsModal = ({ onClose, onAccept }) => {
  const PAGE_NAME = 'Terms'
  const { t } = useTranslation()
  return (
    <ModalFocusTrap unmountTrap={onClose}>
      <div className="modal-body">
        <div className="inline-title">
          <h1 id="dialog-heading">{t(`${PAGE_NAME}.title`)}</h1>
          <button onClick={onClose} aria-label={t('Common.closeButton')} className="close-button">
            <CloseIcon />
          </button>
        </div>
        <TermsContent parentName={PAGE_NAME} isModal={true} />
        <div className="modal-nav">
          <div className="inline-padding">
            <button onClick={onAccept} className="button is-primary">
              {t(`${PAGE_NAME}.accept`)}
            </button>
          </div>
        </div>
      </div>
    </ModalFocusTrap>
  )
}

export default TermsModal