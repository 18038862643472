import React from "react"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
import { SplitFactory } from "@splitsoftware/splitio-react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import "app/js/utils/localization/i18n"
import "app/resources/styles/sass/App.sass"
import { getEnableReCaptcha, getRecaptchaApiKey } from "app/js/configuration/environmentConfig"
import Navigation from "app/js/Navigation"
import { getSplitConfig } from "app/js/configuration/environmentConfig"

const NavigationWithReCaptcha = () => (
  <GoogleReCaptchaProvider reCaptchaKey={getRecaptchaApiKey()}>
    <Navigation />
  </GoogleReCaptchaProvider>
)

const App = () => {
  const { i18n } = useTranslation()
  const lang = i18n.language.includes('fr') ? 'fr-ca' : 'en-ca'
  return (
    <SplitFactory config={getSplitConfig()}>
      <Helmet>
        <html lang={lang}></html>
      </Helmet>
      {getEnableReCaptcha() ? <NavigationWithReCaptcha /> : <Navigation />}
    </SplitFactory>
  )
}

export default App
