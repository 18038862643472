import axios from 'axios'
import { getEnableReCaptcha, getServiceBaseUri, getServiceBaseUriM4, getServiceHost } from 'app/js/configuration/environmentConfig'


const enrolmentService = {
  createAccount: ({ email, claimNumber, postalCode, lang }, recaptchaToken, useM4Endpoint) => {
    const URI = `${useM4Endpoint ? getServiceBaseUriM4() : getServiceBaseUri()}/users`
    const paramData = {
      email: email,
      claim_number: claimNumber,
      postal_code: postalCode,
      lang: lang,
      recaptcha: {
        token: recaptchaToken,
        bypass: !getEnableReCaptcha()
      }
    }
    if (typeof lang != "undefined") { paramData.lang = lang }
    return axios(
      {
        method: 'post',
        url: `${getServiceHost()}${URI}`,
        data: paramData
      })
  },

  activateToken: (token, recaptchaToken, useM4Endpoint) => {
    const URI = `${useM4Endpoint ? getServiceBaseUriM4() : getServiceBaseUri()}/authn`
    const paramData = {
      token: token,
      recaptcha: {
        token: recaptchaToken,
        bypass: !getEnableReCaptcha()
      }
    }
    return axios(
      {
        method: 'post',
        url: `${getServiceHost()}${URI}`,
        data: paramData
      })
  },

  activateAccount: ({ stateToken, password }, recaptchaToken, useM4Endpoint) => {
    const URI = `${useM4Endpoint ? getServiceBaseUriM4() : getServiceBaseUri()}/authn/credentials/reset_password`
    const paramData = {
      state_token: stateToken,
      new_password: password,
      recaptcha: {
        token: recaptchaToken,
        bypass: !getEnableReCaptcha()
      }
    }
    return axios(
      {
        method: 'post',
        url: `${getServiceHost()}${URI}`,
        data: paramData
      })
  },

  resetPassword: ({ email }, recaptchaToken, useM4Endpoint) => {
    const URI = `${useM4Endpoint ? getServiceBaseUriM4() : getServiceBaseUri()}/users/${email}/recover`
    return axios(
      {
        method: 'post',
        url: `${getServiceHost()}${URI}`,
        data: {
          recaptcha: {
            token: recaptchaToken,
            bypass: !getEnableReCaptcha()
          }
        },
      })
  },

  authnExpiry: (useM4Endpoint) => {
    const URI = `${useM4Endpoint ? getServiceBaseUriM4() : getServiceBaseUri()}/authn/expiry`
    return axios(
      {
        method: 'post',
        url: `${getServiceHost()}${URI}`
      })
  }
}

export default enrolmentService