import React from 'react'
import { useTranslation } from 'react-i18next'

const ErrorMessage = ({ parentName, name, errors, id }) => {
  const { t } = useTranslation()
  const error = errors[name]
  if (error) {
    const baseKey = `${parentName}.${name}.validation.${error.message || error.type}`
    const label = t([`${baseKey}.label`, baseKey])
    return <p className="error" id={id}>{label}</p>
  }
  return null
}

export default ErrorMessage