import React from 'react'
import PageHelp from 'app/js/components/common/PageHelp/PageHelp'
import { useTranslation } from 'react-i18next'

const RecaptchaPageHelp = () => {
  const { t } = useTranslation()

  const GOOGLE_PRIVACY_POLICY_URL = t('Common.pageHelp.recaptcha.googlePrivacyPolicyUrl')
  const GOOGLE_TERMS_URL = t('Common.pageHelp.recaptcha.googleTermsUrl')

  const GOOGLE_PRIVACY_POLICY_LABEL = t('Common.pageHelp.recaptcha.googlePrivacyPolicyLabel')
  const GOOGLE_TERMS_LABEL = t('Common.pageHelp.recaptcha.googleTermsLabel')

  const GOOGLE_PRIVACY_POLICY_LINK = (
    <a aria-label={GOOGLE_PRIVACY_POLICY_LABEL} href={GOOGLE_PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
      dummyText
    </a>
  )

  const GOOGLE_TERMS_LINK = (
    <a aria-label={GOOGLE_TERMS_LABEL} href={GOOGLE_TERMS_URL} target="_blank" rel="noopener noreferrer">
      dummyText
    </a>
  )

  return (
    <div className="recaptcha-help">
      <PageHelp
        msgKey={`Common.pageHelp.recaptcha.message`}
        components={[GOOGLE_PRIVACY_POLICY_LINK, GOOGLE_TERMS_LINK]}
      />
    </div>

  )
}

export default RecaptchaPageHelp